import 'select2';
import 'owl.carousel';

export default function Songs() {
	$( '.songs-list>.songs>article>.song-info>.play>a' ).on( 'click', function ( e ) {
		e.preventDefault();

		const i = $( this ).find( 'i' );
		if ( i.hasClass( 'fa-play-circle-o' ) ) {
			i.removeClass( 'fa-play-circle-o' ).addClass( 'fa-times-circle-o' );
		} else {
			i.removeClass( 'fa-times-circle-o' ).addClass( 'fa-play-circle-o' );
		}

		$( this ).parents( 'article.song' ).find( '.song-spotify' ).slideToggle();
	} );

	$( '.songs-filters select' ).select2();
	$( '.songs-filters select' ).on( 'change', function ( e ) {
		const val = $( this ).val();
		if ( val ) {
			window.location.href = val;
		}
	} );

	$( '#song-video .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		dots: true,
		nav: false,
		video: true,
		onInitialized: function () {
			$( '.owl-dot' ).each( function () {
				let idx = $( this ).index() + 1;
				$( this ).append( '<span class="sr-only">Go to slide ' + idx + '</span>' );
			} );
		}
	} );
}
