import Nav from './components/nav';
import Popup from './components/popup';
import Home from './components/home';
import Transpose from './components/transpose';
import MySongs from './components/mysongs';
import Songs from './components/songs';
import Social from './components/social';
import Artists from './components/artists';

jQuery( document ).ready( function ( $ ) {
	Nav();
	Popup();
	Home();
	Transpose();
	MySongs();
	Songs();
	Social();
	Artists();
} );
