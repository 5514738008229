export default function Social() {

	$( '.share-links>li>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		popupWindow( href, 'Share', window, 670, 378 );
	} );


	function popupWindow( url, title, win, w, h ) {
		const y = win.top.outerHeight / 2 + win.top.screenY - (
			h / 2
		);
		const x = win.top.outerWidth / 2 + win.top.screenX - (
			w / 2
		);
		return win.open( url, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}` );
	}

	$( '#community_form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const self = $( this );

		$.ajax( {
			        url: wp.ajax_url,
			        method: 'POST',
			        data: {
				        'action': 'text_us',
				        'first_name': self.find( '#first_name' ).val(),
				        'last_name': self.find( '#last_name' ).val(),
				        'email': self.find( '#email' ).val(),
				        'phone': self.find( '#phone' ).val(),
				        '_wpnonce': self.find( '#_wpnonce' ).val(),
			        },
			        dataType: 'json',
			        success: function ( response, textStatus, jqXHR ) {
				        console.log( response );
				        alert( response.data.message );
			        },
			        error: function ( jqXHR, textStatus, errorThrown ) {
				        console.log( textStatus );
				        alert( 'An error has occurred!' );
			        }
		        } );
	} );
}
