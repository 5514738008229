import * as Transposer from 'chord-transposer';

export default function Transpose() {
	$( '#transpose_select > select' ).on( 'change', function ( e ) {
		e.preventDefault();
		const key = $( this ).val();
		const chords = $( '#chords' );

		const result = Transposer.transpose( chords.text() ).toKey( key ).toString();
		chords.html( result );
	} );
}
