import 'magnific-popup';
import Cookies from 'js-cookie';

export default function MySongs() {

	function planningCenterAdd( song_id ) {
		$.ajax( {
			url: wp.ajax_url,
			method: 'POST',
			data: {
				'action': 'planning_center_add_song',
				'song_id': song_id,
				'_wpnonce': wp.planning_center_add_song_nonce
			},
			dataType: 'json',
			success: function ( response, textStatus, jqXHR ) {
				console.log( response );
				alert( response.data.message );
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				console.log( textStatus );
				alert( 'An error has occurred!' );
			}
		} );
	}

	var code_interval;
	$( 'body.logged-in .song-add-planning' ).on( 'click', function ( e ) {
		e.preventDefault();
		const song_id = $( this ).attr( 'data-song-id' );
		clearTimeout( code_interval );

		if ( Cookies.get( 'pc_is_authed' ) ) {
			planningCenterAdd( song_id );
		} else {
			const popup = popupWindow( wp.planning_center_oauth_url, 'Share', window, 800, 600 );
			code_interval = setInterval( function () {
				const response = popup.location.href.match( /^.*\?code\=(.*)$/ );
				if ( response[1] ) {
					const code = response[1];
					clearTimeout( code_interval );
					popup.close();

					$.ajax( {
						url: wp.ajax_url,
						method: 'POST',
						data: {
							'action': 'planning_center_code_to_token',
							'code': code,
							'_wpnonce': wp.planning_center_code_nonce
						},
						dataType: 'json',
						success: function ( response, textStatus, jqXHR ) {
							if ( response.success ) {
								console.log( response.data.message );
								Cookies.set( 'pc_is_authed', true, {
									expires: new Date( new Date().getTime() + 115 * 60 * 1000 )
								} );
								planningCenterAdd( song_id );
							} else {
								alert( response.data.message );
							}
						},
						error: function ( jqXHR, textStatus, errorThrown ) {
							alert( 'An error has occurred!' );
						}
					} );
				}
			}, 1000 );
		}
	} );

	$( 'body.logged-in .song-add-songs, #create-collection, #edit-collection, #delete-collection' ).magnificPopup( {
		type: 'inline',
		midClick: true
	} );

	$( '#select-playlist form select' ).on( 'change', function ( e ) {
		e.preventDefault();
		const val = $( this ).val();
		if ( val ) {
			$( '#create-playlist' ).hide();
		} else {
			$( '#create-playlist' ).show();
		}
	} );

	$( '#select-playlist form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const button = $( '#select-playlist form button' );

		$.ajax( {
			url: wp.ajax_url,
			method: 'POST',
			data: $( this ).serialize(),
			dataType: 'json',
			beforeSend: function () {
				button.prop( 'disabled', true ).text( 'Saving...' );
			},
			success: function ( response, textStatus, jqXHR ) {
				if ( response.success ) {
					button.text( response.data.message );
					setTimeout( function () {
						$.magnificPopup.close();
						button.text( 'Save' );
					}, 1500 );
				} else {
					alert( response.data.message );
				}
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				button.prop( 'disabled', false ).text( 'Save' );
				alert( 'An error has occurred!' );
			}
		} );
	} );

	function popupWindow( url, title, win, w, h ) {
		const y = win.top.outerHeight / 2 + win.top.screenY - (
			h / 2
		);
		const x = win.top.outerWidth / 2 + win.top.screenX - (
			w / 2
		);
		return win.open( url, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}` );
	}

	$( '#create-playlist form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const button = $( '#create-playlist form button' );

		$.ajax( {
			url: wp.ajax_url,
			method: 'POST',
			data: $( this ).serialize(),
			dataType: 'json',
			beforeSend: function () {
				button.prop( 'disabled', true ).text( 'Creating...' );
			},
			success: function ( response, textStatus, jqXHR ) {
				if ( response.success ) {
					$.magnificPopup.close();
					window.location.reload();
				} else {
					alert( response.data.message );
				}
				button.prop( 'disabled', false ).text( 'Create' );
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				button.prop( 'disabled', false ).text( 'Create' );
				alert( 'An error has occurred!' );
			}
		} );
	} );

	$( '#edit-playlist-title form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const button = $( '#edit-playlist-title form button' );

		$.ajax( {
			url: wp.ajax_url,
			method: 'POST',
			data: $( this ).serialize(),
			dataType: 'json',
			beforeSend: function () {
				button.prop( 'disabled', true ).text( 'Saving...' );
			},
			success: function ( response, textStatus, jqXHR ) {
				if ( response.success ) {
					$.magnificPopup.close();
					window.location.reload();
				} else {
					alert( response.data.message );
				}
				button.prop( 'disabled', false ).text( 'Save' );
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				button.prop( 'disabled', false ).text( 'Save' );
				alert( 'An error has occurred!' );
			}
		} );
	} );

	$( '#delete-playlist form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const button = $( '#delete-playlist form button' );

		$.ajax( {
			url: wp.ajax_url,
			method: 'POST',
			data: $( this ).serialize(),
			dataType: 'json',
			beforeSend: function () {
				button.prop( 'disabled', true ).text( 'Deleting...' );
			},
			success: function ( response, textStatus, jqXHR ) {
				if ( response.success ) {
					$.magnificPopup.close();
					window.location.href = response.data.url;
				} else {
					alert( response.data.message );
				}
				button.prop( 'disabled', false ).text( 'Delete' );
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				button.prop( 'disabled', false ).text( 'Delete' );
				alert( 'An error has occurred!' );
			}
		} );
	} );

	$( '#save-collection' ).on( 'submit', function ( e ) {
		e.preventDefault();
		let ids = [];
		$( '#song-collection-tracks .song' ).each( function ( index, element ) {
			const id = $( element ).attr( 'data-song-id' );
			ids.push( id );
		} );
		$( '#songs' ).val( ids.join( ',' ) );

		const button = $( '#save-collection button' );

		$.ajax( {
			url: wp.ajax_url,
			method: 'POST',
			data: $( '#save-collection' ).serialize(),
			dataType: 'json',
			beforeSend: function () {
				button.prop( 'disabled', true ).text( 'Saving Playlist...' );
			},
			success: function ( response, textStatus, jqXHR ) {
				if ( response.success ) {
					button.text( 'Saved Playlist!' );
					setTimeout( function () {
						button.text( 'Save Playlist' );
					}, 1500 );
				} else {
					alert( response.data.message );
					button.prop( 'disabled', false ).text( 'Save Playlist' );
				}
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				button.prop( 'disabled', false ).text( 'Save Playlist' );
				alert( 'An error has occurred!' );
			}
		} );
	} );

	$( '#song-collection-tracks .song .controls .delete-song' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).parents( '.song' ).remove();
	} );

	$( '#song-collection-tracks .songs' ).sortable( {
		handle: '.move-song', placeholder: 'ui-state-highlight', start: function ( e, ui ) {
			ui.placeholder.height( ui.item.height() );
		}
	} );
}